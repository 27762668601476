import Player from "@vimeo/player";
import { gsap, Power4, utils } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";

$(document).ready(function () {
  console.log("extern")
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(DrawSVGPlugin);

  var imageTextArray = [
    $(".image-text").find("img"),
    $(".image-text").find("h2"),
    $(".image-text").find("p"),
  ];

  if ($(".image-text").find("h5").length)
    imageTextArray.push($(".image-text").find("h5"));
  gsap.set(imageTextArray[0], {
    opacity: 0,
    y: -10,
    scale: 1.1,
  });
  gsap.set(imageTextArray[1], {
    opacity: 0,
    x: 25,
  });
  gsap.set(imageTextArray[2], {
    opacity: 0,
    x: 25,
  });
  gsap.set(imageTextArray[3], {
    opacity: 0,
    x: 25,
  });

  const sections = gsap.utils.toArray(".svg-embed");

  sections.forEach((section) => {
    gsap.from(section.querySelectorAll("path"), {
      drawSVG: 0,
      duration: 0.5,
      stagger: 0.1,
      overwrite: 1,
      scrollTrigger: {
        trigger: section,
        start: "top 80%",
        once: true,
        markers: false,
      },
    });
  });

  const triggerSection = $(".image-text-anim");

  ScrollTrigger.batch(imageTextArray, {
    markers: false,
    trigger: ".image-text-anim",
    start: "top 50%",
    end: "+=75%", // try different options here
    scrub: true,
    onEnter: (batch) => {
      batch.forEach((batch, index) =>
        gsap.to(batch, {
          ease: Power4.easeOut,
          opacity: 1,
          x: 0,
          y: 0,
          scale: 1,
          stagger: {
            amount: 0.2,
            grid: "auto",
            from: "top", //[1, 0.5] would be right center, [0.5, 1] would be center bottom, etc. Any decimal will work!
          },
          delay: index * 0.2,
        })
      );
    },
    once: true,
  });
});
